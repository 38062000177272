































import { walletStore } from '@/stores/wallet-store'
import { autorun, IReactionDisposer } from 'mobx'
import { Observer } from 'mobx-vue'
import { Component, PropSync, Vue } from 'vue-property-decorator'

@Observer
@Component
export default class ConnectWalletStatus extends Vue {
  @PropSync('drawer') drawerDialog!: boolean

  wallet = walletStore

  btn = ''
  text = ''
  css = ''

  disposes: IReactionDisposer[] = []

  promise() {
    if (!this.wallet.account) {
      this.wallet.showConnectWalletDialog(true)
    } else if (!this.wallet.isRegisted) {
      if (this.$route.path !== '/register') this.$router.push('/register')
      this.drawerDialog = false
    } else if (!this.wallet.isLogin) {
      this.wallet.signIn()
    } else {
      if (this.$route.path !== '/mypage') this.$router.push('/mypage')
      this.drawerDialog = false
    }
  }

  mounted() {
    this.disposes = [
      autorun(() => {
        if (!walletStore.account) {
          this.btn = 'connect-wallet'
          this.text = 'Connect Wallet'
          this.css = 'error--text'
        } else if (!this.wallet.isRegisted) {
          this.btn = 'error register-button'
          this.text = 'Register'
          this.css = ''
        } else if (!this.wallet.isLogin) {
          this.btn = 'btnD login-button'
          this.text = 'Login'
          this.css = ''
        } else {
          this.btn = 'btnC my-page-button'
          this.text = 'My page'
          this.css = 'primary--text'
        }
      }),
    ]
  }
  beforeDestroy() {
    this.disposes.forEach((d) => d())
  }
}
